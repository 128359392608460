<template>
  <BaseList title="Proyectos" toName='Nuevo Proyecto'>
    <tabla-proyectos />
  </BaseList>
</template>

<script>
import TablaProyectos from "@/components/proyectos/table.vue";
import BaseList from '@/components/shared/BaseList'

export default {
  components: {
    TablaProyectos,
    BaseList,
  },
};
</script>
