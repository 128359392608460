<template>
  <div>
    <TablaProyectos
      :items="proyectos"
      :openEditPosicion="openEditPosicion"
      :handleChangeDestacado="handleChangeDestacado"
      :handleDeleteProyecto="handleDeleteProyecto"
      :loading="loading"
    />
    <ModalEditarPosicion
      :loading="loading"
      v-model="modalEditPosicion"
      :key="keyModal"
      @hidden="proyecto = { posicion: null }"
      :posicion="proyecto.posicion"
      @onChangePosicion="(e) => (proyecto.posicion = e)"
      :handleUpdatePosicion="handleUpdatePosicion"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FIELDS_TABLA_PROYECTO } from "@/constants/proyecto";
import TablaProyectos from "@/components/tabla/proyectos";
import ModalEditarPosicion from "@/components/modal/editarPosicion";
import {
  MSG_SWAL_POSICION_ACTUALIZADA,
  MSG_SWAL_POSICION_ACTUALIZADA_ERROR,
  MSG_SWAL_ELIMINAR,
} from "@/constants/swal";

export default {
  components: {
    TablaProyectos,
    ModalEditarPosicion,
  },
  data() {
    return {
      loading: false,
      modalEditPosicion: false,
      proyecto: { posicion: null },
      fields: JSON.parse(JSON.stringify(FIELDS_TABLA_PROYECTO)),
      keyModal: 1,
    };
  },
  methods: {
    ...mapActions({
      getProyectos: "proyectos/getProyectos",
      deleteProyecto: "proyectos/deleteProyecto",
      updatePosicion: "proyectos/updatePosicion",
      updateDestacado: "proyectos/updateDestacado",
    }),
    async handleChangeDestacado(proyecto) {
      this.loading = true;
      const res = await this.updateDestacado({
        id: proyecto.id,
        destacado: !proyecto.destacado,
      });
      if (res) {
        this.$swal({
          title: "Proyecto destacado",
          text: "El proyecto ha sido marcado como destacado",
          icon: "success",
          button: "Ok",
        });
        this.loading = false;
      } else {
        this.$swal({
          title: "Error",
          text: "No se pudo marcar el proyecto como destacado",
          icon: "error",
          button: "Ok",
        });
        this.loading = false;
      }
    },
    openEditPosicion(proyecto) {
      this.proyecto = { ...proyecto };
      this.modalEditPosicion = true;
      this.keyModal++;
    },
    async handleUpdatePosicion() {
      this.loading = true;
      const res = await this.updatePosicion({
        id: this.proyecto.id,
        posicion: this.proyecto.posicion,
      });
      if (res) {
        this.$swal(MSG_SWAL_POSICION_ACTUALIZADA);
        this.modalEditPosicion = false;
      } else {
        this.$swal(MSG_SWAL_POSICION_ACTUALIZADA_ERROR);
      }
      this.loading = false;
    },
    async handleDeleteProyecto(id) {
      const result = await this.$swal.fire(MSG_SWAL_ELIMINAR);

      if (!result.isConfirmed) return;

      const res = await this.deleteProyecto(id);

      if (res) {
        return this.$swal("Exito", "Proyecto eliminado", "success");
      }

      this.$swal("Error", "No se pudo eliminar el proyecto", "error");
    },
  },
  computed: {
    ...mapGetters({
      proyectos: "proyectos/proyectos",
    }),
  },
  mounted() {
    this.getProyectos();
  },
};
</script>